import React from 'react'
import { Link } from 'react-router-dom'

import { API } from 'aws-amplify'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'

import { IconManifest, IconTree } from 'react-icons/'
import { HiOutlineArrowRight, HiOutlineStar } from 'react-icons/hi'

import PostsList from '../components/PostsList'
import { Header, SubHeader } from '../components/Text'
import { channels } from '../config'
import { useAuthenticator } from '@aws-amplify/ui-react'

export default function ListChannels() {
    const [userSettings, setUserSettings] = React.useState({})
    const favoriteChannels = userSettings.favoriteChannels ? userSettings.favoriteChannels : []

    const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus])

    React.useEffect(() => {
        fetchUserSettings()
    }, [authStatus])

    function fetchUserSettings() {
        if (authStatus === "authenticated" && user.username) {
            API.graphql({
                query: queries['getUserSettings'],
                variables: {
                    user: user.username
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            }).then(({ data }) => {
                setUserSettings(data.getUserSettings)
            }).catch((error) => {
                console.error("error fetching user settings")
                console.error(error)
            })
        }
    }

    function updateFavoriteChannels(value) {
        if (authStatus === "authenticated" && user.username) {
            API.graphql({
                query: mutations.updateUserSettings,
                variables: {
                    input: {
                        user: user.username,
                        favoriteChannels: value
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            }).then(({ data }) => {
                setUserSettings(data.updateUserSettings)
            }).catch((error) => {
                console.error("error updating favorite channels:", error)
            })
        }
    }

    function addFavoriteChannel(channel) {
        let favorites = favoriteChannels
        if (!favorites.includes(channel)) {
            favorites.push(channel)
            updateFavoriteChannels(favorites)
        }
    }

    function removeFavoriteChannel(channel) {
        let favorites = favoriteChannels
        if (favorites.includes(channel)) {
            favorites = favorites.filter(x => x != channel)
            updateFavoriteChannels(favorites)
        }
    }

    return (
        <div>
            <div className='text-center'>
                <Header text="Channels" />
            </div>

            {
                channels.map(({ name, icon }) => {
                    const isFavorite = favoriteChannels.includes(name)

                    return (
                        <div className='mb-8 mt-4' key={name}>
                            <h2 className='text-2xl font-bold inline align-baseline'>
                                <HiOutlineStar onClick={isFavorite ? () => removeFavoriteChannel(name) : () => addFavoriteChannel(name)} className='h-4 w-4 m-2 inline align-middle cursor-pointer' fill={isFavorite ? "orange" : "transparent"} />
                                <Link to={`/channel/${name}`}>
                                    {name}
                                    <HiOutlineArrowRight className='h-4 w-4 m-2 inline align-middle' />
                                </Link>
                            </h2>
                            <PostsList channel={name} limit={4} showChannelBadge={false} />
                        </div>
                    )
                })
            }

        </div>
    )
}
