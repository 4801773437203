import React from 'react'

import { Authenticator, useAuthenticator, TextField, Button } from '@aws-amplify/ui-react'
import { useNavigate, redirect } from 'react-router-dom'
import { Header } from '../components/Text'
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Auth } from 'aws-amplify';
import { LoaderContext } from '../Context';
import NotificationContext from '../Notifications';


export default function LoginView() {
    const { setLoading } = React.useContext(LoaderContext)
    const { setNotification } = React.useContext(NotificationContext)
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const [formState, setFormState] = React.useState({
        username: null,
        password: null,
        error: false,
        showPassword: false
    })

    const navigate = useNavigate();

    React.useEffect(() => {
        console.log("Auth status now:", authStatus)
        if (authStatus === 'authenticated') {
            navigate("/", { replace: true });
            window.location.reload(true)
        }
    }, [authStatus]);

    function login() {
        const { username, password } = formState
        if (username === "" || password === "") {
            setFormState({
                ...formState,
                error: true
            })
        } else {
            setLoading(true)
            console.log("attempting log in...")
            Auth.signIn(username, password)
                .then((user) => {
                    navigate("/", { replace: true });
                    window.location.reload(true)
                })
                .catch((error) => {
                    setNotification("Oops...", "Invalid username or password", "error")
                    console.error("error signin in:", error)
                }).finally(() => {
                    setLoading(false)
                })
        }
    }

    function forgotPassword() {
        console.log("init forgot password")
    }

    return (
        <div>
            <div className='mt-24 flex flex-col gap-4'>
                <Header text={"Log In"} />
                <TextField
                    label="Username"
                    isRequired={true}
                    hasError={formState.error && formState.username === ""}
                    name="username"
                    onChange={(e) => setFormState({ ...formState, username: e.target.value })} />

                <TextField
                    label="Password"
                    isRequired={true}
                    hasError={formState.error && formState.password === ""}
                    name="password"
                    type={formState.showPassword ? "text" : "password"}
                    outerEndComponent={
                        <Button onClick={() => setFormState({ ...formState, showPassword: !formState.showPassword })}>
                            {formState.showPassword ? (<HiEyeOff />) : (<HiEye />)}
                        </Button>
                    }
                    onChange={(e) => setFormState({ ...formState, password: e.target.value })} />

                <Button className='w-full' onClick={login} type="submit">Login</Button>
                <span className='text-center w-full cursor-pointer' onClick={() => navigate("/signup")}>Don't have an account?</span>
                <span className='text-center w-full cursor-pointer' onClick={forgotPassword}>Forgot my password</span>
            </div>

            {/* 
            <div className='mt-24'>
                <Authenticator initialState="signIn" />
            </div> */}
        </div>
    )
}
