/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://xgnmkoxa5ff53f6gmkdpqlgrrq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uzreoi7xp5c2hncdiqjkxyy77i",
    "aws_cognito_identity_pool_id": "us-east-1:cd412125-d4a4-4d95-ba68-f990f5910238",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Y0YBM2wH3",
    "aws_user_pools_web_client_id": "566ldbqe8k74mkd7hsp39fenvd",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "thebackprop-20230127214347-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://thebackprop-20230127214347-hostingbucket-staging.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
