import React from 'react'

import { getPost } from '../graphql/queries'
import { API } from 'aws-amplify'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { HiArrowLeft } from 'react-icons/hi'
import { Header, SubHeader } from '../components/Text'
import { marked } from 'marked'


const HEADER_SIZING = {
    1: '4xl',
    2: '3xl',
    3: '2xl',
    4: 'xl',
    5: 'lg',
    6: 'md',
}


// Override function
// block level:
// code(string code, string infostring, boolean escaped)
// blockquote(string quote)
// html(string html)
// heading(string text, number level, string raw, Slugger slugger)
// hr()
// list(string body, boolean ordered, number start)
// listitem(string text, boolean task, boolean checked)
// checkbox(boolean checked)
// paragraph(string text)
// table(string header, string body)
// tablerow(string content)
// tablecell(string content, object flags)

// inline:
// strong(string text)
// em(string text)
// codespan(string code)
// br()
// del(string text)
// link(string href, string title, string text)
// image(string href, string title, string text)
// text(string text)

const renderer = {
    heading(text, level) {
        return `<h${level} class="text-${HEADER_SIZING[level]} my-2">${text}</h${level}>`;
    },
    link(href, title, text) {
        if (! href.startsWith("http")) {
            href = "https://" + href
        }
        return `<a href="${href}" class="underline">${text}</a>`
    },
    code(code, language, escaped) {
        console.log(code, language, escaped)
        return `<div class="my-2 py-2 px-4 bg-slate-700 rounded-xl"><code class="text-white font-mono text-md">${code}</code></div>`
    }
};

marked.use({ renderer });


export default function PostView() {
    const { postID } = useParams()
    const [post, setPost] = React.useState({})
    const [postContent, setPostContent] = React.useState("")
    const navigate = useNavigate()

    React.useEffect(() => {
        if (postID && post.id !== postID) {
            API.graphql({
                query: getPost,
                variables: { id: postID }
            }).then(({ data }) => {
                setPost(data.getPost)

                try {
                    const _content = marked.parse(data.getPost.content)
                    setPostContent(_content)
                } catch (error) {
                    console.error("error parsing markdown of content:", data.getPost.content)
                    setPostContent(data.getPost.content)
                }
            }).catch((error) => {
                console.error("Error fetching data", error)
            })
        }
    }, [postID])

    console.log(post)

    return (
        <div>
            <HiArrowLeft className='h-8 w-8 m-2 cursor-pointer' onClick={() => navigate(-1)} />

            <div className=''>
                <div className='text-center my-8'>
                    <Header text={post.title} />
                    <h3 className="my-2">
                        by <Link to={`/user/${post.user}/posts`} className='underline cursor-pointer'>{post.user}</Link>
                    </h3>
                </div>

                <p className='text-xl whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: postContent }}></p>
            </div>
        </div>
    )
}
