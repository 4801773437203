import React from 'react'

import { Authenticator, useAuthenticator, TextField, Button } from '@aws-amplify/ui-react'
import { useNavigate, redirect } from 'react-router-dom'
import { Header } from '../components/Text'
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Auth } from 'aws-amplify';
import { LoaderContext } from '../Context';

const validateEmail = (email) => {
    if (email === null || email === undefined || email === "") {
        return false
    }

    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

};


export default function SignUpView() {
    const { setLoading } = React.useContext(LoaderContext)
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const [formState, setFormState] = React.useState({
        username: null,
        email: null,
        password: null,
        usernameError: false,
        emailError: false,
        passwordError: false,
        showPassword: false
    })

    const navigate = useNavigate();

    React.useEffect(() => {
        console.log("Auth status now:", authStatus)
        if (authStatus === 'authenticated') {
            navigate("/", { replace: true });
            window.location.reload(true)
        }
    }, [authStatus]);


    function validateForm() {
        let valid = true

        const { username, email, password } = formState

        if (username === "") {
            setFormState({ ...formState, usernameError: true })
            valid = false
        }

        if (!validateEmail(email)) {
            setFormState({ ...formState, emailError: true })
            valid = false
        }

        if (password.length < 8) {
            setFormState({ ...formState, passwordError: true })
            valid = false
        }

        return valid
    }


    function register() {
        if (validateForm()) {
            const { username, email, password } = formState
            console.log("registering ", username, email, password)
        }
    }

    return (
        <div>
            <div className='mt-24 flex flex-col gap-4'>
                <Header text={"Join thebackprop.net"} />

                <TextField
                    label="Username"
                    isRequired={true}
                    hasError={formState.usernameError}
                    name="username"
                    type="text"
                    onChange={(e) => setFormState({ ...formState, username: e.target.value })} />

                <TextField
                    label="Email"
                    isRequired={true}
                    hasError={formState.emailError}
                    name="email"
                    type="email"
                    onChange={(e) => setFormState({ ...formState, email: e.target.value })} />

                <TextField
                    label="Password"
                    isRequired={true}
                    hasError={formState.passwordError}
                    name="password"
                    type={formState.showPassword ? "text" : "password"}
                    outerEndComponent={
                        <Button onClick={() => setFormState({ ...formState, showPassword: !formState.showPassword })}>
                            {formState.showPassword ? (<HiEyeOff />) : (<HiEye />)}
                        </Button>
                    }
                    onChange={(e) => setFormState({ ...formState, password: e.target.value })} />

                <Button className='w-full' onClick={register} type="submit">Join</Button>
                <span className='text-center w-full cursor-pointer' onClick={() => navigate("/login")}>Already have an account?</span>
            </div>
        </div>
    )
}
