import { Badge } from '@aws-amplify/ui-react'
import React from 'react'
import { HiArrowDown, HiArrowUp } from 'react-icons/hi'
import { Link } from 'react-router-dom'


const UPVOTE_COLOR = "fill-green-600"
const DOWNVOTE_COLOR = "fill-red-600"

export default function PostPreview({ post, showPostedBy = true, showChannelBadge = true }) {

    function _upvote() {
        console.log("upvoting post:", post.id)
    }

    function _downvote() {
        console.log("downvoting post:", post.id)
    }

    function _delete() {
        console.log("deleting post:", post.id)
    }

    return (
        <div className='flex flex-row justify-between border border-black my-4 px-4 py-2'>
            <p className=''>
                <Link to={"/post/" + post.id}><span className='text-xl font-bold inline'>{post.title}</span></Link>
                {
                    showPostedBy ? (
                        <span className='ml-4'>
                            by <Link to={`/user/${post.user}/posts`} className='underline cursor-pointer'>{post.user}</Link>
                        </span>
                    ) : ""
                }
            </p>

            {/* actions */}
            <div className='whitespace-nowrap'>
                { showChannelBadge ? (<Link to={`/channel/${post.channel}`}><Badge className='mr-6'>{post.channel}</Badge></Link>) : ""}
                <button onClick={_downvote}>{post.downvotes}<HiArrowDown className='w-4 h-4 m-2 inline' /></button>
                <button onClick={_upvote}>{post.upvotes}<HiArrowUp className='w-4 h-4 m-2 inline' /></button>
            </div>
        </div>
    )
}
