import React from 'react'
import { Badge } from '@aws-amplify/ui-react'


export default function JobPosting({job}) {
    return (
        <div className='border border-black px-4 py-2'>
            <a href={job._link} target="_blank"><strong>{job.position}</strong></a>
            <br /> @ {job.company} [{job.location}]
            <br /> {job.skills.map(skill => (<Badge key={skill} size="small" className='mr-2 mt-2'>{skill}</Badge>))}
        </div>
    )
}
