/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      content
      channel
      upvotes
      downvotes
      relatedURL
      tags
      user
      owner
      _type
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        channel
        upvotes
        downvotes
        relatedURL
        tags
        user
        owner
        _type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsInChannel = /* GraphQL */ `
  query PostsInChannel(
    $channel: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsInChannel(
      channel: $channel
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        channel
        upvotes
        downvotes
        relatedURL
        tags
        user
        owner
        _type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByUser = /* GraphQL */ `
  query PostsByUser(
    $user: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        channel
        upvotes
        downvotes
        relatedURL
        tags
        user
        owner
        _type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postByDate = /* GraphQL */ `
  query PostByDate(
    $_type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postByDate(
      _type: $_type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        channel
        upvotes
        downvotes
        relatedURL
        tags
        user
        owner
        _type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobPost = /* GraphQL */ `
  query GetJobPost($id: ID!) {
    getJobPost(id: $id) {
      id
      position
      company
      description
      location
      jobType
      relatedURL
      skills
      owner
      _type
      createdAt
      updatedAt
    }
  }
`;
export const listJobPosts = /* GraphQL */ `
  query ListJobPosts(
    $filter: ModelJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        position
        company
        description
        location
        jobType
        relatedURL
        skills
        owner
        _type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobByCompany = /* GraphQL */ `
  query JobByCompany(
    $company: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByCompany(
      company: $company
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        company
        description
        location
        jobType
        relatedURL
        skills
        owner
        _type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByDate = /* GraphQL */ `
  query JobsByDate(
    $_type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByDate(
      _type: $_type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        position
        company
        description
        location
        jobType
        relatedURL
        skills
        owner
        _type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($user: String!) {
    getUserSettings(user: $user) {
      user
      favoriteChannels
      bookmarks
      upvotes
      downvotes
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserSettings = /* GraphQL */ `
  query ListUserSettings(
    $user: String
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSettings(
      user: $user
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user
        favoriteChannels
        bookmarks
        upvotes
        downvotes
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
